
import { Component, Vue, Provide, Emit, Prop } from 'vue-property-decorator'
import ChangeMobileMixin from '@/mixins/changeMobile'
import GlobalMixin from '@/mixins/global'

@Component({
  name: 'BindPhoneVerificationCode',
  components: {
  },
  mixins: [GlobalMixin, ChangeMobileMixin]
})

export default class VerificationCode extends Vue {
  public name = 'VerificationCode'

  @Prop({
    type: String,
    default: ''
  }) mobile!: string

  @Prop({
    type: Boolean,
    default: false
  }) isFromLife!: boolean // 是否来自来福商城

  @Prop({
    type: Boolean,
    default: true
  }) isFromPublic!: boolean // 是否来自M站

  @Prop({
    type: Boolean,
    default: true
  }) replacePhone!: boolean // 是否是修改安全手机

  @Prop({
    type: String,
    default: ''
  }) token!: string // 修改安全手机需要传入token

  @Provide() public code: string = ''
  @Provide() public codeArr: any = []
  @Provide() public errorMsg: string = ''
  @Provide() public lastMobile: string = ''

  @Emit()
  public async input () {
    await this.login()
  }
  @Emit()
  public async login () {
    this.codeArr = String(this.code).split('') || []
    if (this.codeArr.length === 6) {
      const [err, res] = await this.modifyMobile(this.mobile, String(this.code), this.token, this.replacePhone)
      if (err && err.error && err.message) {
        this.errorMsg = err.message
      } else {
        this.$toasted.show(this.replacePhone ? '修改成功！' : '绑定成功')
        const self = this
        setTimeout(() => {
          if (self.isFromLife) {
            const wx = (window as any).wx
            wx.miniProgram.navigateBack({
              delta: 1,
              fail: () => {
                wx.miniProgram.switchTab({
                  url: 'pages/index/index'
                })
              }
            })
            this.$router.go(-1)
            return
          }
          if (self.isFromPublic) {
            const wx = (window as any).wx
            wx.miniProgram.navigateBack({
              delta: 1
            })
            return
          }
          if (document.referrer.includes('m.benlai.com')) {
            location.replace(`https://m.benlai.com/userHome/securityCenter${location.search}`)
          } else {
            location.replace(`https://mc.benlai.com/safeCenter/SafeCenter`)
          }
        }, 2500)
      }
    }
  }
  @Emit()
  public async sendCode () {
    await this.clearCountDown()
    const [err, res] = await this.sendV5SMSCode(this.mobile, 5)
    if (err && err.error && err.message) {
      this.errorMsg = err.message
    } else {
      this.$toasted.show('验证码获取成功！')
      this.errorMsg = ''
    }
  }
}
