import { Component, Vue, Provide, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import randomString from '@/utils/random'
const CryptoJS = require('crypto-js')
const BLOCK_SECOND: number = 60
let timeout: any

declare module 'vue/types/vue' {
  interface Vue {
    remainingSecond: number, // 倒计时时间S
    sendV5SMSCode(mobile: string, type: number): Promise<any>, // v5获取验证码
    startCountDown(): void, // 倒计时方法
    getMobile(): Promise<any>,
    verifyMobile(code: string): Promise<any>,
    modifyMobile(phone: string, code: string, token: string, replacePhone: boolean): Promise<any>,
    clearCountDown(): void,
  }
}

@Component
export default class ChangeMobileMixin extends Vue {
  @Provide() public remainingSecond: number = 0
  @Provide() public sent: boolean = false

  get smsText () {
    if (this.remainingSecond > 0) {
      return `${this.remainingSecond}s`
    }
    return this.sent ? '重新获取' : '获取验证码'
  }
  get smsTextForSafe () {
    if (this.remainingSecond > 0) {
      return `${this.remainingSecond}s`
    }
    return this.sent ? '重新获取' : '获取验证码'
  }

  get canSend () {
    return this.remainingSecond <= 0
  }

  public async sendV5SMSCode (mobile: string = '', type: number = 1) {
    if (this.remainingSecond > 0) {
      return [undefined, undefined]
    }
    try {
      const res = await httpHelper.post({
        url: 'sms',
        data: {
          phone: mobile,
          smsType: type
        },
        type: 'apiv5'
      })
      this.sent = true
      this.startCountDown()
      return [undefined, res]
    } catch (err) {
      return [err, undefined]
    }
  }

  public startCountDown () {
    const self = this
    this.remainingSecond = BLOCK_SECOND
    clearInterval(timeout)
    timeout = setInterval(() => {
      const newRemain = self.remainingSecond - 1
      if (newRemain < 0) {
        clearInterval(timeout)
        timeout = undefined
        return
      }
      self.remainingSecond = newRemain
    }, 1000)
  }

  // 获取用户绑定的安全手机
  public async getMobile () {
    try {
      const res = await httpHelper.get({
        url: 'IAccount/BindPhone',
        type: 'apiv5'
      })
      return [undefined, res]
    } catch (err) {
      return [err, undefined]
    }
  }

  public async verifyMobile (code: string = '') {
    try {
      const res = await httpHelper.post({
        url: 'IAccount/ValidatePhone',
        data: {
          code: code
        },
        type: 'apiv5'
      })
      return [undefined, res]
    } catch (err) {
      return [err, undefined]
    }
  }

  public async modifyMobile (phone: string = '', code: string = '', token: string = '', replacePhone: boolean = false) {
    const data: any = {
      phone: phone,
      code: code,
      token: token,
      replacePhone: replacePhone
    }
    let iv = randomString(16)
    // let iv = 'P67P2jdDrNCpzQXt'
    const time = Math.round(Date.now() / 1000)
    const message = `${phone}|${time}`
    // const message = '15858585858|1637056682'
    // const key = 'k*^JjnSlZ&3Ro&w+fj%glN7%ITU9FLav'
    const key = CryptoJS.enc.Utf8.parse('k*^JjnSlZ&3Ro&w+fj%glN7%ITU9FLav')
    data.salt = iv
    iv = CryptoJS.enc.Utf8.parse(iv)
    const encrypted = CryptoJS.AES.encrypt(message,
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })
    data.phone = encrypted.toString()
    try {
      const res = await httpHelper.post({
        url: 'IAccount/BindPhone',
        data,
        type: 'apiv5'
      })
      return [undefined, res]
    } catch (err) {
      return [err, undefined]
    }
  }

  public clearCountDown () {
    this.remainingSecond = 0
    this.sent = false
  }
}
